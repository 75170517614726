export const MENU_SET_INFO = 'Menu/MENU_SET_INFO';

export const PRODUCT_SET_DEFAULT = 'Menu/PRODUCT_SET_DEFAULT';

export const BUILD_COMPOSITIONS = 'Menu/BUILD_COMPOSITIONS';

export const PRODUCT_REMOVE_OPTION_VALUE = 'PRODUCT_REMOVE_OPTION_VALUE';

export const PRODUCT_SET_OPTION_UNIQUE_CHOICE =
    'PRODUCT_SET_OPTION_UNIQUE_CHOICE';

export const PRODUCT_SET_OPTION_UNIQUE_EXTRA =
    'PRODUCT_SET_OPTION_UNIQUE_EXTRA';

export const PRODUCT_SET_OPTION_UNIQUE_EXTRA_PLUS =
    'PRODUCT_SET_OPTION_UNIQUE_EXTRA_PLUS';

export const PRODUCT_SET_OPTION_MULTIPLE_EXTRA =
    'PRODUCT_SET_OPTION_MULTIPLE_EXTRA';

export const PRODUCT_SET_OPTION_MULTIPLE_EXTRA_PLUS =
    'PRODUCT_SET_OPTION_MULTIPLE_EXTRA_PLUS';

export const PRODUCT_SET_BASE_GROUP_OPTION = 'PRODUCT_SET_BASE_GROUP_OPTION';

export const PRODUCT_SET_OPTION_UNIQUE_CHOICE_FOR_A_GROUP_OPTION =
    'PRODUCT_SET_OPTION_UNIQUE_CHOICE_FOR_A_GROUP_OPTION';

export const PRODUCT_SET_OPTION_UNIQUE_EXTRA_FOR_A_GROUP_OPTION =
    'PRODUCT_SET_OPTION_UNIQUE_EXTRA_FOR_A_GROUP_OPTION';

export const PRODUCT_SET_OPTION_UNIQUE_EXTRA_PLUS_FOR_A_GROUP_OPTION =
    'PRODUCT_SET_OPTION_UNIQUE_EXTRA_PLUS_FOR_A_GROUP_OPTION';

export const PRODUCT_SET_OPTION_MULTIPLE_EXTRA_FOR_A_GROUP_OPTION =
    'PRODUCT_SET_OPTION_MULTIPLE_EXTRA_FOR_A_GROUP_OPTION';

export const PRODUCT_SET_OPTION_MULTIPLE_EXTRA_PLUS_FOR_A_GROUP_OPTION =
    'PRODUCT_SET_OPTION_MULTIPLE_EXTRA_PLUS_FOR_A_GROUP_OPTION';

export const PRODUCT_SET_OPTION_REPLACE = 'PRODUCT_SET_OPTION_REPLACE';

export const COMPOSITION_ADD_ONE = 'COMPOSITION_ADD_ONE';
export const COMPOSITION_REMOVE_ONE = 'COMPOSITION_REMOVE_ONE';
export const SET_COMPOSITION_QUANTITY = 'SET_COMPOSITION_QUANTITY';

export const CHANGE_SLICE_PRICE = 'CHANGE_SLICE_PRICE';

export const HANDLE_REMOVAL_INGREDIENTS = 'Menu/HANDLE_REMOVAL_INGREDIENTS';
export const FORMULA_HANDLE_REMOVAL_INGREDIENTS =
    'Menu/FORMULA_HANDLE_REMOVAL_INGREDIENTS';

export const SET_PLANNING = 'SET_PLANNING';

export const REDUCED_REGIME = 'REDUCED_REGIME';

export const RESET_CART = 'RESET_CART';
export const CART_PRODUCT_ADD = 'Menu/CART_PRODUCT_ADD';
export const CART_PRODUCT_REMOVE_BY_PRODUCT_ID =
    'Menu/CART_PRODUCT_REMOVE_BY_PRODUCT_ID';
export const CART_PRODUCT_REMOVE = 'Menu/CART_PRODUCT_REMOVE';
export const CART_PRODUCT_ADD_ONE = 'Menu/CART_PRODUCT_ADD_ONE';
export const CART_PRODUCT_UPDATE_QUANTITY = 'Menu/CART_PRODUCT_UPDATE_QUANTITY';
export const CART_PRODUCT_REMOVE_ONE = 'Menu/CART_PRODUCT_REMOVE_ONE';
export const CART_SET_COMMENT = 'Menu/CART_SET_COMMENT';
export const CART_SET_CUSTOMER_FIRSTNAME = 'Menu/CART_SET_CUSTOMER_FIRSTNAME';
export const COMPOSITION_SET_COMMENT = 'Menu/compositionSetComment';
export const CART_SET_NB_PEOPLE = 'Menu/CART_SET_NB_PEOPLE';
export const SET_SCHEDULE = 'SET_SCHEDULE';
export const SET_CART_USER_INFO = 'SET_CART_USER_INFO';
export const NEW_BOOKING_REQUEST = 'NEW_BOOKING_REQUEST';
export const SET_VENUE = 'SET_VENUE';
export const FORMULA_CURRENT = 'FORMULA_CURRENT';
export const FORMULA_ITEM_REMOVE = 'FORMULA_ITEM_REMOVE';
export const FORMULA_ITEM_ADD = 'FORMULA_ITEM_ADD';
export const CONFIRM_FORMULA = 'CONFIRM_FORMULA';
export const DELETE_CART_FORMULA = 'DELETE_CART_FORMULA';
export const REMOVE_ONE_QUANTITY_TO_FORMULA = 'REMOVE_ONE_QUANTITY_TO_FORMULA';
export const ADD_ONE_QUANTITY_TO_FORMULA = 'ADD_ONE_QUANTITY_TO_FORMULA';

export const SET_SELECTED_REGIMES = 'SET_SELECTED_REGIMES';

export const SET_CURRENCY = 'SET_CURRENCY';

export const SET_BROWSER_LANG = 'SET_BROWSER_LANG';

export const ORDER_SENDING = 'ORDER_SENDING';
export const BOOKING_SENDING = 'BOOKING_SENDING';

export const COMPOSITION_RANDOM = 'COMPOSITION_RANDOM';

export const SET_ELIOR_MENU_TYPE = 'SET_ELIOR_MENU_TYPE';

export const COMPOSITION_FORMULA_ADD_OPTION = 'COMPOSITION_FORMULA_ADD_OPTION';
export const COMPOSITION_FORMULA_REMOVE_OPTION =
    'COMPOSITION_FORMULA_REMOVE_OPTION';

export const PRODUCT_FORMULA_SET_OPTION_UNIQUE_CHOICE =
    'PRODUCT_FORMULA_SET_OPTION_UNIQUE_CHOICE';

export const PRODUCT_FORMULA_SET_OPTION_UNIQUE_EXTRA =
    'PRODUCT_FORMULA_SET_OPTION_UNIQUE_EXTRA';

export const PRODUCT_FORMULA_SET_OPTION_UNIQUE_EXTRA_PLUS =
    'PRODUCT_FORMULA_SET_OPTION_UNIQUE_EXTRA_PLUS';

export const PRODUCT_FORMULA_SET_OPTION_MULTIPLE_EXTRA =
    'PRODUCT_FORMULA_SET_OPTION_MULTIPLE_EXTRA';

export const PRODUCT_FORMULA_SET_OPTION_MULTIPLE_EXTRA_PLUS =
    'PRODUCT_FORMULA_SET_OPTION_MULTIPLE_EXTRA_PLUS';

export const PRODUCT_DISCOUNT_ADD = 'PRODUCT_DISCOUNT_ADD';

export const PRODUCT_DISCOUNT_REMOVE = 'PRODUCT_DISCOUNT_REMOVE';

export const SET_SELECTED_MENU = 'SET_SELECTED_MENU';
export const SET_MENU_TYPE = 'SET_MENU_TYPE';
